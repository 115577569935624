import PropTypes from 'prop-types';
import React, { cloneElement, useMemo } from 'react';

import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { RichTextEditor } from '@/design-system/atoms/RichTextEditor';
import { Theme } from '@/design-system/atoms/Theme';
import { Title } from '@/design-system/atoms/Title';

import styles from './ContactUsForm.module.scss';

const ContactUsForm = ({
    htmlId,
    title,
    description,
    formComponent,
    theme = Theme.NAME.MEDIUM,
    ...props
}) => {
    const analyticsFormComponent = useMemo(() => {
        const analyticsObj = { componentName: 'Contact Form', componentTitle: title };
        return formComponent
            ? cloneElement(formComponent, {
                  analytics: analyticsObj,
                  formType: 'contactus',
              })
            : null;
    }, [formComponent, title]);

    return (
        <section id={htmlId} className={styles['contactus-form']}>
            <Theme name={theme}>
                <ContentContainer className={styles[`contactus-form__wrapper`]}>
                    <Grid sm={4} md={6} lg={12}>
                        <GridColumn
                            colSpan={{ sm: 4, md: 6, lg: 6 }}
                            className={styles[`contactus-form__left`]}
                        >
                            {title && (
                                <Title
                                    className={styles[`contactus-form__title`]}
                                    variant={Title.VARIANT.H3}
                                    tag={Title.TAG.H2}
                                    lineClamp={3}
                                    content={title}
                                />
                            )}
                            {description && (
                                <div className={styles[`contactus-form__subtext`]}>
                                    <RichTextEditor text={description} />
                                </div>
                            )}
                        </GridColumn>
                        <GridColumn
                            colSpan={{ sm: 4, md: 6, lg: 6 }}
                            className={styles[`contactus-form__right`]}
                        >
                            {analyticsFormComponent}
                        </GridColumn>
                    </Grid>
                </ContentContainer>
            </Theme>
        </section>
    );
};

ContactUsForm.propTypes = {
    /**
     * title
     */
    title: PropTypes.string,
    /**
     * description: html content
     */
    description: PropTypes.string,
    /**
     * html Id
     */
    htmlId: PropTypes.string,
    /**
     * form component
     */
    formComponent: PropTypes.node,
    /**
     * Theme name to apply.
     */
    name: PropTypes.oneOf(Object.values(Theme.NAME)),
};

export default ContactUsForm;
